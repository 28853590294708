// import logo from './logo.svg';
import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import Cointable from "./components/Cointable";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
// import Routes from "./components/Routes";
import Routeers from "./components/Routes";
function App() {
  const [Coindata, setCoinData] = useState([]);
  const [shoModal, setShowModal] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [loading, setloading] = useState(false);
  // https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false&locale=en

  useEffect(()=>{
const fetchdata=async()=>{
try {
  const res= await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`)
  const result=await res.json()
console.log(result)
setCoinData(result)
} catch (error) {
  console.log(error)
}

 
}
fetchdata()
  },[])

const handlemodal=()=>{
  setShowModal(true)
}
  return (
    <div className="App">
      <Routeers/>
      {
        shoModal?"":  <Cointable Coindata={Coindata} handlemodal={handlemodal}/>
      }
    
    </div>
  );
}

export default App;
