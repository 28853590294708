import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function SingleCoindata() {
    const {id}=useParams()
    console.log(id)
    const [Coindata, setCoinData] = useState([]);
    const [shoModal, setShowModal] = useState(false);
    const [currentpage, setcurrentpage] = useState(1);
    const [loading, setloading] = useState(false);
    // https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false&locale=en
  
    useEffect(()=>{
  const fetchdata=async()=>{
  try {
    const res= await fetch(`https://api.coingecko.com/api/v3/coins/${id}`)
    const result=await res.json()
  console.log(result)
  setCoinData(result)
  } catch (error) {
    console.log(error)
  }
  
   
  }
  fetchdata()
    },[])
  return (
    <div>
<p>coinname:{Coindata.name}</p>
{/* <img src={Coindata.image.large} alt="" /> */}
<p>coinsybbol:{Coindata.symbol}</p>
<p>currentprice:{Coindata.converted_volume.usd
}</p>

    </div>
  )
}
