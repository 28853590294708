import React from 'react'
import { Routes,Route } from 'react-router-dom'
import SingleCoindata from './singleCoindata'
import Cointable from './Cointable'
export default function Routeers() {
  return (
    <Routes>
<Route path='/coin/:id' element={<SingleCoindata/>}/>
<Route path="/" element={<Cointable/>}/>
    </Routes>
  )
}
