import React from "react";
import CoinTableCard from "../card/CoinTableCard";
import { Link, useNavigate } from "react-router-dom";

export default function Cointable({ Coindata ,handlemodal}) {
  console.log(Coindata);
  const navigate = useNavigate();
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Coin</th>
            <th>Price</th>
            <th>24h Change</th>
            <th>Market Cap</th>
          </tr>
        </thead>
        <tbody>
          {Coindata?.map((coin) => {
            return (
              <tr key={coin?.id} onClick={() =>{
                navigate(`/coin/${coin.id}`,{replace:true})
                return handlemodal()
              }}>
                <CoinTableCard key={coin?.id} coin={coin} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
