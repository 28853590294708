import React from 'react'
import { Link, Navigate } from 'react-router-dom'

function CoinTableCard({coin}) {
    let str=coin.symbol
    let str1=str.toUpperCase()
  return (
   <>
        <td>
     
             <div>
           <img src={coin.image} alt={coin.name} />
           <div>
           <p>{str1}</p>
            <p>{coin.name}</p>
           </div>
            
            </div>
        
            </td>
       <td>{coin.current_price}</td>
       <td>{coin.price_change_percentage_24h}%</td>
       <td>{coin.market_cap}</td>
       </>
  
  )
}

export default CoinTableCard